import { g } from '@pretto/bricks/components/layout'
import { bodyBold16, bodyBook16 } from '@pretto/bricks/core/typography'

import { ChevronDownBold, Picto as PictoType } from '@pretto/picto'

import { ReactElement, useState } from 'react'
import styled, { css } from 'styled-components'

interface AccordionProps {
  description: string
  title: string
  value: string | ReactElement
  picto: PictoType
}

export const Accordion = ({ description, title, value, picto, ...props }: AccordionProps) => {
  const [isOpen, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!isOpen)
  }

  return (
    <MainContainer onClick={handleClick} {...props}>
      <Picto as={picto} />

      <RightContainer>
        <HeaderContainer>
          <InfoContainer>
            <Title>{title}&nbsp;</Title>
            <Value>{value}</Value>
          </InfoContainer>

          <Toggle as={ChevronDownBold} $isOpen={isOpen} />
        </HeaderContainer>

        <BodyContainer $isOpen={isOpen}>
          <Description $isOpen={isOpen}>{description}</Description>
        </BodyContainer>
      </RightContainer>
    </MainContainer>
  )
}

const Row = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`
interface DescriptionStateProps {
  $isOpen: boolean
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${g(2)};
`

const Picto = styled.svg`
  min-height: ${g(4)};
  min-width: ${g(4)};
  color: ${({ theme }) => theme.colors.neutral1};
  align-self: flex-start;
  margin-top: ${g(1)};
`

const RightContainer = styled.div`
  width: 100%;
  padding-top: ${g(1, 4)};
`

const HeaderContainer = styled.div`
  ${Row};
  width: 100%;
`

const InfoContainer = styled.div`
  ${Row};
  width: 100%;
`

const Title = styled.div`
  ${bodyBook16}
  color: ${({ theme }) => theme.colors.neutral1};
  text-transform: uppercase;
`

const Value = styled.div`
  color: ${({ theme }) => theme.colors.neutral1};
  ${bodyBold16}
  white-space: nowrap;
`

const Toggle = styled(Picto)<DescriptionStateProps>`
  min-height: ${g(2)};
  min-width: ${g(2)};
  color: ${({ theme }) => theme.colors.neutral1};
  justify-self: flex-end;
  transition: transform 0.5s linear;
  transform: ${({ $isOpen }) => ($isOpen ? `rotate(180deg)` : `rotate(0deg)`)};
`

const BodyContainer = styled.div<DescriptionStateProps>`
  width: 100%;
  margin-top: ${g(1, 4)};
  max-height: ${({ $isOpen }) => ($isOpen ? '300px' : '0px')};
  transition: max-height 0.5s linear;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};
`

const Description = styled.div<DescriptionStateProps>`
  ${bodyBook16}
  margin-top: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
`
