import { g } from '@pretto/bricks/components/layout'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import { bodyBold16, caption12, link12 } from '@pretto/bricks/core/typography'

import { Accordion } from '@pretto/zen/seo/reasonToBelieve/ReasonToBelieve/components/Accordion'
import { Square, SquareProps } from '@pretto/zen/seo/reasonToBelieve/ReasonToBelieve/components/Square'

import { ReactElement } from 'react'
import styled from 'styled-components'

export interface ReasonToBelieveProps {
  infoDescription: string
  infoTitle: string
  infoValue: string | ReactElement
  picto: SquareProps['picto']
}

export const ReasonToBelieve = ({ infoDescription, infoTitle, infoValue, picto, ...props }: ReasonToBelieveProps) => {
  return (
    <>
      <ResponsiveSSR max="laptop">
        <Accordion description={infoDescription} title={infoTitle} value={infoValue} picto={picto} {...props} />
      </ResponsiveSSR>

      <ResponsiveSSR min="laptop">
        <MainContainer {...props}>
          <Square picto={picto} />

          <BankInfoContainer>
            <Title>{infoTitle}</Title>
            <Value>{infoValue}</Value>
            <Description>{infoDescription}</Description>
          </BankInfoContainer>
        </MainContainer>
      </ResponsiveSSR>
    </>
  )
}

const MainContainer = styled.div`
  position: relative;
  min-width: ${g(26)};
  min-height: ${g(18)};
  padding: ${g(2)} ${g(3)};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  overflow: hidden;
`

const BankInfoContainer = styled.div`
  position: absolute;
  top: ${g(7)};
  bottom: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  text-align: center;
  padding-left: ${g(2)};
  padding-right: ${g(2)};
  transition: top 0.1s linear, background-color 0.1s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MainContainer}:hover > & {
    background-color: white;
    top: 0px;
  }
`

const Title = styled.p`
  ${link12}
  color: ${({ theme }) => theme.colors.neutral1};
  text-transform: uppercase;
  text-align: center;
`

const Value = styled.div`
  ${bodyBold16}
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
`

const Description = styled.div`
  ${caption12}
  margin-top: ${g(1)};
  color: ${({ theme }) => theme.colors.neutral1};
  display: none;

  ${MainContainer}:hover > ${BankInfoContainer} > & {
    display: block;
  }
`
