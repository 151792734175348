import { g } from '@pretto/bricks/components/layout'

import { Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

export interface SquareProps {
  picto: PictoType
}

export const Square = ({ picto }: SquareProps) => {
  return (
    <MainContainer>
      <Picto as={picto} />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: ${g(7)};
  height: ${g(7)};
  border-radius: ${g(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary4};
`

const Picto = styled.svg`
  color: ${({ theme }) => theme.colors.primary1};
`
